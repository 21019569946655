import React, { KeyboardEvent, useEffect, useRef, useState } from "react";
import Layout from "../../components/layout";
import { FiLogOut } from "react-icons/fi";

import styles from "./DashboardPage.module.scss";
import { POST } from "../../services/axios.service";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { route } from "../../static/route";
import { getLoggingUser } from "../../features/loggingUser/loggingUserSlice";
import { useAppSelector } from "../../app/hooks";
import { QrCodeProps } from "../../types/qrcode";
import moment from "moment";

const DashboardPage = () => {
  const navigate = useNavigate();
  const { setIsAuthenticated } = useAuth();
  const loggingUser = useAppSelector(getLoggingUser);
  const inputRef = useRef<HTMLInputElement>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [qrCodeData, setQrCodeData] = useState<QrCodeProps | null>(null);

  const handleLogout = async () => {
    setIsLoading(true);
    setRequestError(null);

    try {
      await POST("/web-app-v1/logout", {})
        .then(async (response: any) => {
          if (response.data.status === "success") {
            await localStorage.removeItem("authToken");
            await setIsAuthenticated(false);

            navigate(route.login);
          } else {
            setRequestError(response.data.message);
          }
        })
        .catch((error: any) => {
          setRequestError(error.data.message);
        });
    } catch (error) {
      setRequestError("Something went wrong, please try again.");
    }

    setIsLoading(false);
  };

  const hanldeGoToHome = () => {
    navigate(route.dashboard);
  };

  const handleQRCodeInput = async (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setIsLoading(true);
      setRequestError(null);
      setQrCodeData(null);

      try {
        await POST("/web-app-v1/ground-entry", {
          qrcode: inputRef?.current?.value || null,
        })
          .then(async (response: any) => {
            if (response.data.status === "success") {
              setQrCodeData(response?.data?.data || null);
            } else {
              setRequestError(response.data.message || null);
            }
          })
          .catch((error: any) => {
            setRequestError(error.data.message || null);
          });
      } catch (error) {
        setRequestError("Something went wrong, please try again.");
      } finally {
        if (inputRef?.current?.value) inputRef.current.value = "";

        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <Layout>
      {isLoading && (
        <div className="loader-wrapper">
          <div className="loader"></div>
        </div>
      )}

      <div className={styles.DashboardPage}>
        <div className="logoSection" onClick={hanldeGoToHome}>
          <img src="/logo.png" className="m-auto lg:m-0 w-[140px]" alt="" />
        </div>

        <div className="loginInfoSection">
          <div className="grid">
            <h1 className="text-5xl font-bold text-white lg:text-2xl lg:text-primary-1">
              {loggingUser?.name || ""}
            </h1>

            <p className="mt-4 text-white font-semibold text-xl lg:text-lg lg:mt-1 lg:text-primary-2">
              +91 {loggingUser?.phone || ""}
            </p>
          </div>

          <FiLogOut
            className="text-white top-5 right-5 lg:top-0 lg:right-0 absolute lg:relative text-[20px] ml-4 mr-0 m-auto cursor-pointer lg:text-red-600"
            onClick={handleLogout}
          />
        </div>

        <div className="mt-[70px] px-10 py-5">
          <div className="flex flex-col">
            <div className="-mt-14 flex gap-5 justify-center">
              <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-12 w-[500px] relative rounded">
                <input
                  ref={inputRef}
                  className="text-primary-1 px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                  type="text"
                  placeholder="Please enter qrcode number."
                  onKeyPress={handleQRCodeInput}
                />
              </div>

              {/* <button
                className="m-auto shadow disabled:opacity-50 rounded-3xl text-2xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
                type="button"
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "Submit"}
              </button> */}
            </div>

            {requestError !== null && (
              <span className="text-red-500 text-center mt-8">
                {requestError}
              </span>
            )}

            {requestError === null && qrCodeData && (
              <div className="flex w-full justify-center flex-col gap-6">
                <div className="flex w-fit justify-center gap-5 p-10 mx-auto">
                  <img
                    className="max-w-[250px] max-h-[300px] min-w-[250px] min-h-[300px] object-cover rounded-[20px]"
                    src={qrCodeData?.image}
                    alt=""
                  />

                  <div className="flex justify-center flex-col gap-1 w-[500px]">
                    <h2 className="text-[38px] font-semibold text-center">
                      {qrCodeData?.passNumber}
                    </h2>

                    <h2 className="text-[28px] font-semibold text-center">
                      {qrCodeData?.name}
                    </h2>

                    <h2
                      className={`mt-4 text-[100px] font-bold text-center ${
                        (qrCodeData?.groundEntry?.length || 0) <= 1
                          ? "text-green-500"
                          : (qrCodeData?.groundEntry?.length || 0) <= 2
                          ? "text-yellow-500"
                          : "text-red-500"
                      }`}
                    >
                      {qrCodeData?.groundEntry?.length || 0}
                    </h2>
                  </div>
                </div>

                <div className="mx-auto shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="min-w-[250px] px-6 py-3 text-center text-base text-primary-1 font-bold uppercase tracking-wider"
                        >
                          Sr. No.
                        </th>
                        <th
                          scope="col"
                          className="min-w-[250px] px-6 py-3 text-center text-base text-primary-1 font-bold uppercase tracking-wider"
                        >
                          Added By
                        </th>
                        <th
                          scope="col"
                          className="min-w-[250px] px-6 py-3 text-center text-base text-primary-1 font-bold uppercase tracking-wider"
                        >
                          Date
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {qrCodeData?.groundEntry?.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                            {index + 1}
                          </td>
                          <td className="text-center px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                            {item?.admin?.name || "-"}
                          </td>
                          <td className="text-center px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {moment(item.created_at).format(
                              "DD-MM-YYYY hh:mm A"
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default DashboardPage;
